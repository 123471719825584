
@import  "../../assets/variables.scss";

#dw .ant-table { 
    .ant-table-thead > tr > th {
        .filtered {
            color: $brandTwo;
        }
    }
}

.ant-table-filter-dropdown {
    border-radius: map-get($borderRadius, xs);
    top: -3px;
    > div {
        padding: 15px;
        input{
            margin-bottom: 15px;
        }
        .ant-table-filter-dropdown-link{
            cursor: pointer;
        }
    }
}