@import  "../assets/variables.scss";

$menuBorderRadius: map-get($borderRadius, md);

#dw .btn-project-header {
    cursor: pointer;
    min-height: $menuItemHeight;
    font-size: 0.8rem;
    color: $dark;
    border-radius: 0;
    margin-right: 10px;
    padding: 12px 0 12px 10px;
    border-radius: $menuBorderRadius;
    text-decoration: none;
    &.focused{
        z-index: 1001;
        position: relative;
        background: white;
    }
    .project-header {
        margin: 0;
    }
    h1 {
        font-size: 1.4rem;
    }             
}  