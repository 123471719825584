@import  "../assets/variables.scss";

.dashboard{
    .dashboard-progress{
        display: inline-block;
        position: relative !important;
    }
}

.dashboard-header {
    > span:nth-child(3){
        text-transform: uppercase;
        font-weight: 500;
    }
    > span:nth-child(2){
        text-transform: uppercase;
        margin-right: 5px;
        font-weight: 900;
    }
    > span {
        position: relative;
        // - Add a bit distance (20 px) between "PROJECT STATUS" and "APPOINTMENT SCHEDULED"
        font-family: Be Vietnam;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -0.01em;
        color: $brandTwo;
    }
    > h1 {
        margin-bottom: 0;
    }
}
.dashboard-progress{
    > .ant-col:first-child{
        padding-top: 0 !important;
        padding-left: 34px;
        padding-right: 42px;
    }
    > .ant-col:nth-child(2){
        padding-left: 42px;
        padding-right: 34px;
    }
    margin-top: 30px;
    padding: 35px 0;
    width: 100%;
    border: 1px solid $borderGray;
    box-sizing: border-box;
    .ant-col-12:first-child{
        padding-top: 20px;
    }
    p {
        margin-top: 40px;
    }

    .dashboard-progress-tracking{
        > span{   
            font-family: Be Vietnam;
            font-style: italic;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.02em;
            color: $brandTwo;
        }
        > .dashboard-progress-tracking-pages{
            margin-top: 40px;
            > .completed {
                .page-name{
                    color: $brandTwo;
                }
                .progress-text {
                    color: $brandTwo;
                }
                .tracking-page-tick{
                    >div {
                        &:after{
                            background-color: $brandTwo;
                            border-color: $brandTwo;
                        }
                        &:before{
                            position: absolute;
                            display: inline-block;
                            border-radius: 1px;
                            height: 10px;
                            width: 6px;
                            border-right: 2px solid white;
                            border-bottom: 2px solid white;
                            content: ' ';
                            transform: rotate(45deg);
                            text-align: center;
                            left: 11px;
                            top: 2px;
                        }
                    }
                }
            }
            .tracking-page-tick{
                > div::after{
                    display:inline-block;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #A8A8A8;
                    box-sizing: border-box;
                    border-radius: 50%;
                    content: '';
                }
            }
            .progress-text {
                float: right;
                padding-right: 8px;
                color: #999999;
            }
            .page-name{
                color: #999999;
                > a{
                    text-decoration-line: underline;
                }
            }
            span {
                font-family: Work Sans;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: -0.02em;
            }
            .ant-divider-horizontal{
                display: inline-block;
                margin: 10px 0;
            }
        }
    }
}

.dashboard-visualworx-documents{
    margin-top: 24px;
    .ant-col > div{
        padding: 25px;
        display: relative;
        border: 1px solid #CFD2E7;
        box-sizing: border-box;
        height: 339px;
    }
    .ant-col-12:first-child{
        padding-right: 12px;
    }
    .ant-col-12:nth-child(2){
        padding-left: 12px;
    }
    .dashboard-visualworx-div:hover{
        background: #696F8E;
        cursor: pointer;
    }
    .dashboard-visualworx-div{
        background: linear-gradient(358.03deg, #696F8E -1.89%, #7B85B6 98.04%);
        display: flex;
        flex-wrap: wrap;
        .ant-col {
            padding: 10px 0;
            display: flex;
            flex-basis: 100%;
            align-self: center;
            justify-content: center;
                > p{
                    font-family: Be Vietnam !important;
                    font-style: normal!important;
                    font-weight: bold!important;
                    font-size: 30px!important;
                    line-height: 36px!important;
                    text-align: center!important;
                    letter-spacing: -0.01em!important;
                    color: #FFFFFF!important;
            }
        }
    }
}

.documents-none{
    background-color: #F7F7F7 !important;
}
.dashboard-documents-div{
    .documents-view-false{
        display: block;
        margin-top: 90px;
        .ant-col{
            display: flex;
            flex-basis: 100%;
            align-self: center;
            justify-content: center;
            span{
                margin-top: 15px;
                font-family: Be Vietnam;
                font-style: italic;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: -0.02em;
                color: $brandTwo;
            }
        }
    }
    .ant-col-18{
        h3 { 
            margin-bottom: 30px;
        }
    }
    .ant-col-6{
        z-index: 12;
        span:hover{
            cursor: pointer;
        }
        span{
            color: #4E5163 !important;
            position: relative;
            font-family: Work Sans;
            font-style: normal;
            font-weight: bold;
            left: 20px;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-decoration-line: underline;
            color: $brandTwo;
            margin-bottom: 10px;
            top: 9px;
            &::after{
                position: relative;
                left: 7px;
                width: 8px;
                height: 8px;
                display: block;
                content: ' ';
                border-right: 2px solid $brandTwo;
                border-bottom: 2px solid #676A7D;
                transform: rotate(-45deg);
            }
        }
    }
}

.documents-available{
    position: relative;
    display: block;
    > .ant-col:first-child{
        margin-top: 20px;
    }
    > .ant-col{
        width: 100%;
        .document-view{
            margin: 0 !important;
            padding: 0;
            border: none;
            max-height: 120px;
            box-shadow: none;
            > .ant-col-19{
                max-height: 90px;
                h3{
                        font-family: 'Work Sans', sans-serif !important;
                        font-style: normal !important;
                        font-weight: 500 !important;
                        font-size: 18px !important;
                        line-height: 21px !important;
                }
                .props{
                    position: relative;
                    border: none;
                    padding: 0;
                    max-height: 22px;
                    margin-bottom: 0;
                    bottom: 5px;
                }
                > div:nth-child(3){
                    display: none;
                }
                button{
                    position: relative;
                    bottom: 7px;
                    margin-top: 4px;
                    font-size: 13px !important;
                    font-weight: bold !important;
                    height: 18px;
                    border: none;
                    i svg{
                        font-size: 18px;
                    }
                }
            }
            > .ant-col-5{
                padding: 0 !important;
                width: 70px;
                height: 70px;
                .icon{ 
                    width: 70px; height: 70px; padding: 0; border: none;
                    i{
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}