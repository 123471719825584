@import  "../assets/_variables.scss";

$menuBorderRadius: map-get($borderRadius, md);

#dw {
    .sider-holder {
        > .ant-col:nth-child(1){
                padding-top: 42px;
                padding-bottom: 90px;
            .ant-menu:nth-child(1){
                border: none;
                li:nth-child(1){
                    margin-top: 0;
                }
            }
            border-right: 1px solid $eThree;
        }
        
        > .ant-col:nth-child(2){
            .buttons-back-next, .ant-layout-footer, .ant-col-xl-24{
                .ant-btn{
                    margin: 25px 0 !important;
                    height: 45px;
                    font-family: Work Sans;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 19px;
                    min-width: 185px;
                    &.ant-btn-primary {
                       background-color: $textPrimary;
                       border-color: $textPrimary;
                    }
                    &.ant-btn-default {
                        color: $textPrimary;
                        border: 2px solid #CFD2E7;
                    }
                }
            }
        }
    }
    .ant-menu-submenu{
        .ant-menu-item {
            margin: 0 !important;
        }
    }
    .ant-menu.ant-menu-root {
        .anticon-check-circle {
            font-size: 1.3rem;
        }
        .ant-menu-submenu {
            > .ant-menu-submenu-title {
                padding-right: 17px;
                a {
                    font-family: $viatnam;
                    font-weight: 900 !important;
                    display: block;
                    left: 0;
                    right: 0;
                }
            }
        }
        > li {
            min-height: $menuItemHeight;
            text-align: left;

            &.ant-menu-item,
            &.ant-menu-submenu > .ant-menu-submenu-title {
                min-height: $menuItemHeight;
                border-top-left-radius: $menuBorderRadius;
                border-bottom-left-radius: $menuBorderRadius;
                background: $brandThree;
                margin-bottom: 10px;
                margin-top: 10px;
                font-family: $viatnam;
                > a {
                    font-weight: 900;
                    font-size: 1.2rem;
                    color: $brandTwo;
                    min-height: $menuItemHeight;
                    line-height: $menuItemHeight;
                }
            }
            &.ant-menu-submenu > .ant-menu-submenu-title {
                margin-bottom: 0;
                .anticon-check-circle{
                    display: none;
                }      
            }

            &.ant-menu-item-selected,
            &.ant-menu-submenu-open >.ant-menu-submenu-title,
            &.ant-menu-submenu-selected >.ant-menu-submenu-title {
                background: $textPrimary;
                a {
                    color: $bgr;   
                    .anticon-circle{
                        display: none;
                    }                 
                }                
            }
            .ant-menu-sub,
            .ant-menu-submenu {
                .ant-menu-item {
                    &.ant-menu-item-selected {
                        background-color: $brandThree;
                        &::before{
                            position: absolute;
                            content: "";
                            border-style: solid;
                            top: 13px;
                            left: 15px;
                            border-width: 0 8px 13px 8px;
                            border-color: transparent transparent $textPrimary transparent;
                            transform: rotate(90deg);
                        }
                        .anticon-circle{
                            border: 1px solid $placeholder;
                        }  
                    }
                }
                .anticon-circle{
                    margin-top: 11px;
                    border: 1px solid $placeholder;
                }
                .anticon-check-circle {
                    margin-top: 11px;
                    color: $textPrimary;
                }
            }
        }
        .anticon-circle,
        .anticon-check-circle
        {
            float: right;
            margin-top: $menuItemHeight/2 - 10px;
            color: white;
        }
        .ant-menu-submenu-arrow {
            display: none;
        }       
    }
}

.buttons-back-next{
    margin: 25px 0;
}

.ant-btn-primary {
    background-color: $textPrimary;
    border-color: $textPrimary;
 }