@import "./variables";

.container {
    display: block;
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0;
    &.full {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-top: $contentMarginTop;
        > .ant-row-flex {
            margin-top: $contentMarginTop * -1;
            display: flex;
            flex: 1;
            > .ant-col {
                padding: $contentMarginTop 40px 40px 40px;
                
            }
        }
    }
}

#root {
    font-family: Work Sans;
    background: $bgr;
    
    >.ant-layout {
        min-height: 100vh;
        > .ant-layout-header {
            background: white;
            height: 100px;
            border-bottom: 1px solid $stick;
            padding: 0 0!important;
            z-index: 100;
            width: 100%;
            .container{ 
              height: 100px; display: table; 
              a{
                display: table-cell;
                vertical-align: middle;
              }
            }
            .logo{
              display: table-cell;
              vertical-align: middle;
              width: 200px;
              height: 100px;
            }
            .builder-logo{
              max-width: 200px;
              max-height: 90px;
            }
            .bdc-logo {
              background: url(logo.png) center no-repeat;
              width: 140px;
              height: 55px;
            }
            .card-user{
              margin-top: 30px;
              float: right;
            }
        }
        > .ant-layout-content {
            display: flex;
            flex: auto;
            flex-direction: column;
            align-items: stretch;
            .container {
                min-height: 450px;
                padding-top: 0;
                display: flex;
                flex: 1 1 auto !important;
                flex-direction: column;
                .ant-row-flex{
                    margin-left: 0!important;
                    margin-right: 0!important;
                    flex: 1 1 auto;
                }
                .ant-row {
                    flex: 1 1 auto;
                    
                } 
                          
            }            
        }
        > .ant-layout-footer {
            background: $brandOne;
        }
        .ant-layout-footer {
            border-top: 1px solid $stick;
            padding: 4vh 0!important;
            .container {
                padding: 20px 0 20px 0;
                font-size: 14px;
                color: $brandTwo;
                a {
                    color: $brandTwo;
                }
                h4{
                    color: $brandTwo;
                    font-weight: 600;
                    margin-bottom: 20px;
                }               
            }
        }
    }
}
.conteiner {
    margin: auto;
}
  @media only screen and (max-width: 767px) {
    .container {
      width: auto !important;
    }
  }
  
  @media only screen and (max-width: 991px) and (min-width: 768px) {
    .container {
      width: 723px;
    }
  }
  
  @media only screen and (max-width: 1199px) and (min-width: 992px) {
    .container {
      width: 933px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .container {
      width: 1127px;
    }
  }
  
  @media only screen and (min-width: 1366px) {
    .container {
      width: 1200px;
    }
  }
  
  