@import  "../assets/_variables.scss";

.card-user {
    background: white;
    line-height: 38px;
    padding: 0;
    border: 1px $lightGray solid;
    border-radius: 0;
    min-width: 220px;
    z-index: 100;
    a {
        float: left;
        font-weight: 500;
        font-size: 14px;
        color: $textPrimary;
    }
    .ant-avatar {
        margin: 0 12px 0 0;
        padding: 0;
        background-color: $textPrimary;
        border-radius: 0 !important;
    }
    i.anticon-down {
        float: right;
        padding: 11px 0;
        color: $textPrimary;
        font-size: 1em;   
        font-weight: 600; 
        margin: 0px 15px;
    }
    &:hover{
        opacity: 0.8;
    }
}
.ant-dropdown-menu-item:hover{
    background-color: $eThree ;
}