@import  "../assets/variables.scss";

.visualworx-modal{
    .ant-modal-content{
        overflow: hidden;
        height: 100%;
        position: relative;
        width: 100%;
        .ant-modal-body{
            height: 100% !important;
        }
    }
    top: 0 !important;
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0;
    padding: 0;
    .ant-modal-close{
        top:  25px !important;
        right: 25px !important;
    }
}

.visualworx-container{
    p{
        color: $textPrimary;
        font-size: 14px;
    }
}
    .ant-btn-link{
        font-size: 14px !important;
        text-transform: lowercase !important;
        font-weight: 500 !important;
        color: #236B8E !important;
        padding: 0 !important;
    }