@import  "../assets/variables.scss";


#dw .btn-delete {
    display: inline-block;
    > h2 { 
        color: $red;
        margin: 30px 0 10px 0;
        font-size: 0.9rem;
        font-weight: 800;
    }
    > input{ 
        display: inline-block;
        height: 28px;
        max-width: 150px;
        &:focus, &:hover{
            border-color: $red;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
    > button {
        &:disabled,
        &[disabled] 
        {
            background: #f7aeae;
            border-color: #f7aeae;
        }
        left: -30px;
        color: white;
        background: $red;
        border-color: $red;
    }
}