@import  "../assets/variables.scss";

.getting-started-quiz-style, .getting-started-quiz-about{
    .ant-form-item{
        .ant-form-item-label{
                padding: 40px 0;
            > label{
                font-family: Be Vietnam !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 30px !important;
                line-height: 40px !important;
                color: $brandTwo;
            }
        }
    }
}

.getting-started-quiz-style{
    .ant-form-vertical{
        .ant-row:nth-child(2){
            margin: 0 !important;
        }
    }
    .ant-steps{
        margin-bottom: 70px;
    }
    .ant-steps-item {
        margin: 0 auto;
        .ant-steps-item-content{
            position: relative;
            top: 60px;
            right: 17px;
            font-weight: bold;
            margin: 0 auto;
            .ant-steps-item-title{
                line-height: 1.3rem;
            }
        }
        .ant-steps-item-tail{
            position: absolute;
            padding: 0;
            left: 50px;
            margin: 0;
            z-index: 0;
            top: 25px;
            &::after{
                height: 2px;
                background-color: $borderGray;
            }
        }
        .ant-steps-item-icon{
            position: absolute;
            text-align: center;
            border: 2px solid $borderGray;
            z-index: 1;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            i, span{
                font-size: 20px;
            }
        }
    }
        .ant-steps-item-finish{
            .ant-steps-item-title{
                color: $brandTwo !important;
            }
            .ant-steps-item-tail{
                &::after{
                    background-color: $textPrimary;
                }
            }
            .ant-steps-item-icon{
                border: 2px solid $textPrimary !important;
                i{
                    color: $textPrimary !important;
                }
            }
        }
        .ant-steps-item-process{
            .ant-steps-item-title{
                font-weight: bold;
                color: $brandTwo !important;
            }
            .ant-steps-item-icon{
                background-color: $textPrimary;
                border-color: $textPrimary !important;
                i {
                    color: white !important;
                }
            }
        }
        .ant-steps-item-wait{
            .ant-steps-item-title{
                color: $borderGray !important;
            }
            .ant-steps-item-icon{
                border: 2px solid $borderGray;
                i, .ant-steps-icon{
                    color: $borderGray;
                }
            }
        }
        .ant-steps-item-wait:last-of-type{
            .ant-steps-item-content{
                left: 10px;
            }
        }
        .ant-steps-item:last-child {
            flex: 1 1;
        }
        .ant-steps-item-wait:last-child {
            flex: none;
        }
        .ant-steps-item-finish:last-of-type{
            .ant-steps-item-content{
                left: 10px;
            }
        }

        .quiz-step{
            .ant-layout-header{
                display: none;
            }
        }
}


.getting-started-quiz-about{
    .ant-form-vertical{
        .ant-row:nth-child(2){
            margin: 0 !important;
        }
    }


    .btn-project-header{
        padding-left: 0px !important;
        .project-header-progress{
            .ant-progress-inner{
                width: 70px !important;
                height: 70px !important;
            .ant-progress-text{
                i{
                    font-size: 24px !important;
                }
                color: $brandTwo;
                font-weight: bold !important;
                font-family: Be Vietnam;
                font-style: normal;
                font-size: 16px !important;
                line-height: 23px !important;
                letter-spacing: -0.01em !important;
            }
            }
        }
    }
}

#dw .project-header .ant-progress .ant-progress-text svg {
      stroke-width: 100px;
      stroke: #676A7D;
}