@import  "../../assets/variables.scss";

.rate-image {
    width: 100%;
    border: 1px $inputBorderColor solid;
    border-radius: map-get($borderRadius, xs);
    padding: 10px;
    text-align: center;
    margin-bottom: 32px;
    img {
        width: 100%;
        min-height: 150px;
    }
}
.has-error {
    .rate-image {
        border-color: $red;
    }
}