@import  "../assets/variables.scss";

.card-help {
    //TODO remove none if needed (cards)
    display: none;
    box-shadow: 2px 2px 6px rgba(168, 168, 168, 0.4);
    margin-bottom: 30px;
    padding: 40px 20px;
    background: linear-gradient(180deg, #8D9EBC 0%, #87809F 100%);
    .ant-card-meta-title {
        color:white;
        font-size: 1.3rem;
        font-weight: 600;
    }
    .ant-card-meta-description {
        color:white;
    }
    img {
        max-height: 70px;
        max-width: 70px;
        margin: 20px;
    }
    .btn-link{
        position: absolute;
        top: -10px;
        right: 20px;
        color:white;
    }
}
    .btn-project-delete{
        font-family: Work Sans;
        display: block;
        text-decoration-line: underline;
        color: rgb(252, 82, 82); 
        font-size: 13px;
        cursor: pointer;
        width: 100px;
        &:hover{
            color: rgb(253, 143, 143);
        }
    }

.ant-modal-content{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .ant-modal-footer{
        border-top: 0 !important;
    }
    .ant-modal-close-icon{
        position: absolute;
        display: flex;
        color: white;
        background-color: #343644;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        left: 40px;
        bottom: 40px;
        svg{
            position: absolute;
            top: 8px;
            right: 8px;
            font-weight: bolder;
        }
    }
}
.ant-modal-body{
    padding: 50px 80px;
    >h3{
       font-size: 30px !important;
       text-align: center;
       padding: 0 0 30px 0 !important;
   }
}