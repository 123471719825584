@import  "../../assets/_variables.scss";

.getting-started-quiz-style {
    .quiz-step-0{
        .ant-form-item{
            color: $textPrimary;
            .h3{
                font-family: Be Vietnam;
                font-style: normal;
                font-weight: 800;
                font-size: 20px !important;
                line-height: 29px;
                letter-spacing: -0.02em;
            }
            .span{     
                font-family: Work Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
            }
        }
    }
    .check-images{
        .ant-checkbox-wrapper{
            margin-bottom: 40px;
            span:nth-child(2){
                img{
                    background-color: rgb(85, 85, 85);
                }
                div{
                    position: absolute !important;
                    transform: translateY(15px);
                }
            }
        }
}

}
.ant-layout{
    .getting-started-quiz-about{
        .quiz-step-6, .quiz-step-7{
            .check-images{
                .ant-row{
                    display: inline-block !important;
                    .ant-col:nth-child(4){
                        position: relative;
                        left: 33.3%;
                    }
                }
            }
        }
    }
    .darkGreen{
        margin-top: 50px;
    }
    .ant-form-item-label{
        label{
            font-size: 16px !important;
            &::before{
                display: none;
            }
        }
    }
    .ant-radio-wrapper {
        width: 248px;
        height: 248px;
        img {
            width: 70% !important;
        }
    }
}

.check-images {
    padding: 20px 0 0 0;
    width: 100%;
    .ant-checkbox-wrapper,
    .ant-radio-wrapper {
        margin-bottom: 10px;
        text-align: center;
        border: 1px solid#CFD2E7;
        border-radius: map-get($borderRadius, xs);
        img {
            width: 100%;
            min-height: 50px;
            + div:not(:empty){
                padding-top: 10px;
                white-space: normal;
            }
        }
        &.ant-checkbox-wrapper-checked,
        &.ant-radio-wrapper-checked {
            box-shadow: 0px 0px 0px 3px rgb(170, 173, 194);
        }
        
        >.ant-checkbox,
        >.ant-radio 
        {
            display: block;
            visibility: hidden;
            width: 100%;
            height: 100%;
            position: absolute;
            + span {
                display: block;
                padding: 10px;
                height: 100%;
                width: 100%; 
            }
        }
    }
}
.ant-radio-group-solid {
    .ant-radio-button-wrapper-checked {
        background-color: $brandTwo !important;
        border-color: $brandTwo !important;
    }
    .ant-radio-button-wrapper {
        height: auto;
        > span {
            > span {
                > b {
                    display: block;
                    font-size: 1rem;
                }
                margin: 5px 0;
                display: block;
                max-width: 270px;
                line-height: 22px;    
                font-size: 0.9rem;
            }
        }
    }
}
#dw {
    .ant-radio-button-wrapper.image {
        transition: opacity(1);
        border: none !important;
        &:not(:first-child)::before {
                background-color: transparent;
        }
        transition: opacity .5s ease-in-out;
        &:hover{
            opacity: 1;
        }
        &.ant-radio-button-wrapper-checked{
            border: 2px solid $brandTwo !important;
            box-shadow: none;
            &:not(:first-child)::before{
                background-color:transparent;
            }
            &:before{
                background-color:transparent;
            }
        }
    }
}



.getting-started-quiz-about{
    .check-images{
        .ant-row{
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            align-content: flex-start;
            .ant-col .ant-radio-wrapper{
                display: flex;
                padding: 35px 20px;
                width: 100%;
                margin-bottom: 80px;
                span:nth-child(2){
                    margin-bottom: 50px;
                    div{
                        position: relative;
                        font-family: Be Vietnam;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        text-align: center;
                        color: $brandTwo;
                        bottom: -40px;
                        transform: translateY(15px);
                    }
                }
            }
        }
    }
}