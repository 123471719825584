.documents-client-view{
    >h1 {
        margin-bottom: 50px;
    }
    .ant-row{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .ant-col-xl-12:nth-child(odd){
        padding-right: 25px !important;
        padding-left: 0 !important;
    }
    .ant-col-xl-12:nth-child(even){
        padding-right: 0 !important;
        padding-left: 25px !important;
    }
    .ant-divider-horizontal{
        margin-bottom: 40px;
    }
}