@import url('https://fonts.googleapis.com/css?family=Be+Vietnam:400,500,700,800&display=swap');

$bgr: white;
$textPrimary: #676A7D;
$placeholder: #A8A8A8;
$lightGray: #eaeaea;
$darkGray: #3c3c3c;
$red: #f06666;
$dark: #343644;
$inputBorderColor: #d9d9d9;
$eThree: #E3E2E2;

$brandOne: #F7F7F7;
$stick: #DDDDDD;
$brandTwo: #676A7D;
$borderGray: #CFD2E7;
$brandF4: #F4DDD3;
$brandThree: #EAEAEA;

$avenir: 'Avenir Next';
$neutra: 'Neutra Text';
$viatnam: 'Be Vietnam', sans-serif;

$contentMarginTop: 50px;
$borderRadius: (
    xs: 0,
    md: 0,
    xl: 0
);
$cardHeight: 100px; 
$menuItemHeight: 60px;
$modalPadding: 24px;