@import  "../../assets/_variables.scss";

.ant-checkbox-group.button {
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
    }
    .ant-checkbox {
        display: block;
        visibility: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        &.ant-checkbox-checked {
            + button {
                border: 1px $brandTwo solid;
            }
        }
        &:not(.ant-checkbox-checked) + span {
            .ant-btn {
                background: white;
                color: $brandTwo;
                box-shadow: none;
                border-color: $borderGray;
            }   
        }
    }
    .ant-btn {
        padding: 5px 20px;
        margin: 15px auto;
        border-radius: 0 !important;
    }
    .ant-checkbox-wrapper-checked {
            .ant-btn-primary{
                background-color: $brandTwo;
                border-color: $brandTwo;
            }
        } 
}

.ant-radio-checked::after {
    border-radius: 0 !important;
}