.ant-slider {
    margin: 60px 0;
    .ant-slider-mark-text {
        max-width: 160px;
        &:first-child {
            transform: translate(0)!important;
        }
        &:last-child {
            transform: translate(-100%)!important;
            min-width: 160px;
            text-align: right;
        }
    }
}