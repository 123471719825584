@import "../assets/variables.scss";

.ant-notification.ant-notification-topRight{
    text-align: center;
    position: absolute;
    top: 25% !important;
    width: 600px !important;
    left: 50%;
    margin-left: -300px;
    .ant-notification-notice-content{
        margin: 50px 0;
        .ant-notification-notice-icon{
            position: relative;
            i{
                font-size: 42px;
            }
        }
        .ant-notification-notice-message{
            font-family: Be Vietnam;
            font-size: 30px !important;
            line-height: 34px;
            font-weight: bold;
            letter-spacing: -0.01em;
            color: $brandTwo;
            padding: 25px 0;
        }
        .ant-notification-notice-description{
            margin: 0 auto;
            font-family: Work Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            text-align: center;
            color: $brandTwo;
        }
    }
}