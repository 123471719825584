@import  "../assets/variables.scss";

.project-header-banner {
    margin-bottom: $contentMarginTop;
    border-radius: map-get($borderRadius, md);
    font-size: 1rem;
}

.btn-project-header{
    padding: 0 !important;
}
.project-header-progress{
    padding-bottom: 20px;
}
.project-header-text{
    padding: 0 12px 12px 12px;
    position: relative;
    top: -12px;
    h4{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: -0.01em;
        color: $placeholder !important;
        display: flex;
        align-items: center;
        margin: 0;
    }
    h1{
        margin-bottom: 0 !important;
    }
    h3{
        font-style: normal;
        font-weight: bolder;
        color: $brandTwo !important;
        font-size: 2rem !important;
        line-height: 28px;
        letter-spacing: -0.02em;
        text-transform: capitalize !important;
        margin-bottom: 0 !important;
    }
    span{
        color: $brandTwo;
        text-transform: uppercase;
        font-family: Be Vietnam;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: -0.01em;
    }
}


.quiz-about-header{
    border-bottom: 1px solid rgba(141, 158, 188, 0.2);
}