@import  "../../assets/variables.scss";

.list-sort-wrapper {
    user-select: none;
    min-height: 380px;
    margin: 10px auto;
    max-width: 250px;
    width: 90%;
    cursor: grab;
    position: relative;
    &.ready{
        cursor: grabbing;
    }
    .list-sort-item {
        background: #fff;
        border-radius: map-get($borderRadius, xs);
        margin: 5px auto;
        padding: 10px;
        transition: box-shadow 0.5s, transform 0.5s;
        background: #f5f5f5;
        font-size: 1rem;
        &.list-drag-selected {
            box-shadow: 0 8px 20px #e6e6e6;
            transform: scale(1.1) !important;
        } 
    }
}
.list-sort > div {
    overflow: hidden;    
}