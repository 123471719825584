@import  "../../assets/variables";
#dw .admin {
    > .ant-layout-content {
        > .container {
            > .ant-row-flex {
                > .ant-col:nth-child(2) {
                    padding: $contentMarginTop 0 30px 30px!important;
                }
    
            }
        }
    }
     .ant-page-header{
        .ant-page-header-title-view-title {
            font-size: 1.5rem;
            text-transform: uppercase;
        }
        .ant-page-header-title-view-sub-title {
            font-size: 1rem;
            text-transform: uppercase;
        }
    }
   
    .ant-tabs {
        .ant-tabs-bar {
            margin-bottom: 40px;
        }
        .ant-avatar {
            display: block;
            margin: 0px auto;
            background: #f1f0ef;
        }
    }

    .ant-collapse {
        .ant-collapse-header {
            i {
                color: $brandTwo;
            }
            .anticon-circle {
                border-color: $brandTwo;
            }
            .ant-badge{
                float: left;
                margin-right: 25px;
                .ant-badge-count {
                    background: $darkGray;
                }
            }
        }
        .ant-collapse-content {
            padding: 20px 30px;
        }
    }
    .darkGreen {
        color: $brandTwo;
    }
    
    h2 {
        color: #676A7D;
        margin: 0 0 20px 0;
    }
    .left {
        float: left;
    }
}

.admin-file-upload {
    .filepond--drop-label {
        background: white;
        border-width: 1px;
        border-style: dashed; 
        border-color: #CFD2E7;
        label {
            color: #676A7D;
            font-size: 16px;
        }
    }
    &[data-hopper-state="drag-over"]{
        .filepond--drop-label {
            border-color: #676A7D;
        }
    }
}

