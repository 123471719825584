@import  "../assets/variables.scss";
 
#dw .project-header {

    font-size: 0.8rem;
    color: $dark;
    margin: 24px 0;
    .ant-progress {
        float: left;
        margin-right: 10px;
        .ant-progress-text {
            font-size: 0.7rem;
            font-weight: 500;
        }
    }
} 